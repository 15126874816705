<template>
  <el-col :span="24">
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t("message.product") }}</span>
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="close"
        >{{ $t("message.close") }}</el-button
      >
    </header>
    <el-main>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>
            <b>{{ product.name }}</b>
          </span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            class="text-secondary"
          >
            <span>{{ $t("message.code") }}: {{ product.code }}</span>
          </el-button>
        </div>
        <table class="table border-0 text-right">
          <thead>
            <tr>
              <th class="border-0">{{ $t("message.available") }}</th>
              <th class="border-0">{{ $t("message.measurement") }}</th>
              <th class="border-0">{{ $t("message.price") }}</th>
              <th class="border-0">{{ $t("message.total_price") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border-0">{{ product.available | formatNumber }}</td>
              <td class="border-0">
                {{
                  product.main_measurement ? product.main_measurement.name : ""
                }}
              </td>
              <td class="border-0">{{ product.price | formatMoney(2) }}</td>
              <td class="border-0">
                {{ product.total_cost_price | formatMoney(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </el-card>
      <el-col :span="24" class="mt-2">
        <el-card class="box-card">
          <el-table
            v-loading="loading"
            :element-loading-text="$t('message.loading')"
            element-loading-spinner="el-icon-loading"
            ref="filterTable"
            :data="warehouse_products"
            size="mini"
            class="crm-mini-table"
            style="width: 100%"
          >
            <el-table-column :label="$t('message.warehouse')">
              <template slot-scope="props">{{
                props.row.warehouse ? props.row.warehouse.name : ""
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.number')">
              <template slot-scope="props">{{ props.row.origin_id }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.available')">
              <template slot-scope="props">{{
                props.row.remainder | formatNumber
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.day_in_warehouse')">
              <template slot-scope="props">{{
                props.row.day_in_warehouse
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.rate')">
              <template slot-scope="props">
                {{ props.row.rate }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('message.price')">
              <template slot-scope="props"
                >{{ props.row.price | formatNumber(2) }}
                {{
                  props.row.currency ? props.row.currency.symbol : ""
                }}</template
              >
            </el-table-column>
            <el-table-column :label="$t('message.date')">
              <template slot-scope="props">{{ props.row.created_at }}</template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-main>
  </el-col>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";

export default {
  mixins: [drawerChild],
  props: ["product", "warehouseId"],
  data() {
    return {
      loading: false,
      warehouse_products: [],
    };
  },
  methods: {
    ...mapActions({
      showWarehouseProducts: "remainders/show",
    }),
    afterOpened() {
      if (this.warehouseId && this.warehouseId != "") {
        this.loading = true;
        const payload = {
          product_id: this.product.id,
          warehouse_id: this.warehouseId,
        };
        this.showWarehouseProducts(payload).then((response) => {
          this.warehouse_products = response.data.product.warehouse_products;
          this.loading = false;
        });
      } else {
        this.loading = true;
        const payload = { product_id: this.product.id };
        this.showWarehouseProducts(payload).then((response) => {
          this.warehouse_products = response.data.product.warehouse_products;
          this.loading = false;
        });
      }
    },
  },
};
</script>
